








import { Vue, Component, Prop } from 'vue-property-decorator';

import ExerciseIcon from './ExerciseIcon.vue';
import MaterialIcon from './MaterialIcon.vue';
import VideoIcon from './VideoIcon.vue';

@Component({
  components: {
    ExerciseIcon,
    MaterialIcon,
    VideoIcon,
  },
})
export default class ListIcons extends Vue {
@Prop() type!: string;
}
